<template>
  <div id="view-gift-cards-admin">

    <breadcrumb-base :breadcrumb="breadcrumb" title="GiftCards">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>

    <div class="vx-row">

      <div class="vx-col md:w-1/4">
        <vx-card>

          <div class="vx-col py-3">
            <dynamic-customers-drop-down

              :only-seller="true"
              :selected-item="selectedCustomer"
              :placeholder="$t('SearchCustomer')"
              @on-item-selected="onCustomerChanged($event)">
            </dynamic-customers-drop-down>
          </div>

          <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                     @click="filterData">{{ $t('Filter').toUpperCase() }}
          </vs-button>
          <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                     @click="clearAllFilters">{{ $t('ClearAllFilters').toUpperCase() }}
          </vs-button>
        </vx-card>
      </div>
      <div class="vx-col md:w-3/4">

        <!--    Error List State-->
        <transition name="fade">
          <div class="py-6 flex w-full bg-img" v-if="errorFetching">
            <div
              class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
              <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                   class="mx-auto mb-4 max-w-full">
              <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
            </div>
          </div>
        </transition>
        <!--    Empty List State -->
        <transition name="fade">
          <div class="py-6 flex w-full bg-img" v-if="giftCardDetails.length === 0 && !errorFetching">
            <div
              class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--              <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                   class="mx-auto mb-4 max-w-full">-->
              <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
            </div>
          </div>
        </transition>
        <vs-table-modified class="flex-1-1 w-full" v-show="giftCardDetails.length > 0 && !errorFetching"
                           ref="table"
                           :max-items="20"
                           :data="giftCardDetails"
                           v-on:selected="openEditDetails"
        >
          <template slot="thead">
            <vs-th>{{ $t('ProductName') }}</vs-th>
            <vs-th>{{ $t('Denomination') }}</vs-th>
            <vs-th >{{ $t('BatchNo') }}</vs-th>
            <vs-th >{{ $t('CustomerID') }}</vs-th>
            <vs-th >{{ $t('AssignedTo') }}</vs-th>
            <vs-th >{{ $t('Status') }}</vs-th>
            <vs-th >{{ $t('Actions') }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="product-name font-medium truncate">{{ giftCard.product_name }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ giftCard.product_configuration_value | germanNumberFormat }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">{{
                    tr.batch_no
                  }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.assigned_customer_id }}</p>
              </vs-td>
              <vs-td>
                <div class="vs-col">
                  <p class="product-name font-medium truncate">{{ tr.assigned_to }}</p>
                  <p class="truncate" style="font-size: 12px;">{{ tr.assigned_to_first_name }} {{ tr.assigned_to_last_name }}</p>
                </div>
              </vs-td>
              <vs-td>
                <vs-chip :color="tr.status === 0 ? 'danger' : 'success'">{{
                    tr.status === 0 ? $t('UnSold') : $t('Sold')
                  }}
                </vs-chip>
              </vs-td>
              <vs-td>
                <vs-button v-if="tr.status === 0" @click="openEditDetails(tr)">{{ $t('Edit') }}</vs-button>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
          <template slot="footer">
          </template>
          <template slot="footer-left">
            <div class="flex">
              <vs-new-pagination :total="vTotalPages" v-model="currentPage" v-on:change="listPageChanged">
              </vs-new-pagination>
            </div>
          </template>
        </vs-table-modified>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'
import DynamicGiftCardsDropDown from '../products/DynamicGiftCardsDropDown'


export default {
  name: 'ViewGiftCardDetails',
  components: { DynamicCustomersDropDown, DynamicGiftCardsDropDown},
  data() {
    return {
      errorFetching: false,
      selectedCustomer: {},
      giftCard: null,

      currentPage: 1,
    }
  },
  props: {
    card: {
      type: String,
    }
  },
  computed: {
    breadcrumb() {
      if (this.giftCard) {
        return [
          {title: 'Home', i18n: 'Home', url: '/'},
          {title: 'GiftCards', i18n: 'GiftCards', url: '/admin/giftcards'},
          {title: this.giftCard.product_name, active: true},
        ]
      } else {
        return [
          {title: 'Home', i18n: 'Home', url: '/'},
          {title: 'GiftCards', i18n: 'GiftCards', url: '/admin/giftcards'}
        ]
      }
    },
    giftCardDetails() {
      return this.$store.state.adminGiftCards.giftCardDetails
    },
    vPage() {
      return this.$store.state.adminGiftCards.vPage
    },
    vTotalPages() {
      return this.$store.state.adminGiftCards.vTotalPages
    },
    vLimit() {
      return this.$store.state.adminGiftCards.itemCount
    },
  },
  methods: {
    openEditDetails(d) {
      if(d.status === 0) {
        d['product_name'] = this.giftCard.product_name
        d['value'] = this.giftCard.product_configuration_value
        d['config_id'] = this.giftCard.product_configuration_id

        this.$router.push({name: 'admin-giftcard-edit', params: {card: btoa(JSON.stringify(d))}})
      }
    },
    onCustomerChanged(customer) {
      this.selectedCustomer = customer
    },
    clearAllFilters() {
      this.selectedCustomer = {}
      this.fetchGiftCards(this.buildPayload())
    },
    buildPayload() {
      const payload = {}
      if (this.giftCard) {
        payload.product_configuration_id = this.giftCard.product_configuration_id
      }
      if (Object.keys(this.selectedCustomer).length > 0) {
        payload.customer_id = this.selectedCustomer.customer_id
      }
      return payload
    },
    filterData() {
      this.fetchGiftCards(this.buildPayload())
    },
    listPageChanged(item) {
      this.fetchGiftCards(this.buildPayload(), this.currentPage - 1)
    },
    fetchGiftCards(payload = null, page = '0') {
      this.$vs.loading()
      return this.$store.dispatch('adminGiftCards/fetchGiftCardDetails', {payload, page})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          let msg = ''
          try {
            msg = error.response.data.message || error.message
          } catch (err) {
            msg = err.message
          }
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    // this.fetchGiftCards()
  },
  mounted() {
    try {
      this.giftCard = JSON.parse(atob(this.card));
    } catch (e) {
      this.$router.push('/admin/giftcards/')
    }
  }
}
</script>

<style scoped lang="scss">
#view-gift-cards-admin {
  .product-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }
}
</style>
